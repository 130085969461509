import React from 'react';

import {
  Container,
  Heading,
  Text,
  Link,
  Flex,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import Layout from '../components/layout';
import Seo from '../components/layout/seo';
import RouterLink from '../components/basic/routerLink';
import ContactFormModal from '../components/contactFormModal';

const NotFoundPage: React.FC<unknown> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Layout>
      <Seo title="Seite nicht gefunden" />

      <Flex direction="column" justifyContent="center" minHeight="500px">
        <Container height="auto">
          <Box>
            <Heading as="h1" size="xl">
              Seite nicht gefunden
            </Heading>
            <Text fontSize="lg">
              Die Seite die Sie suchen existiert nicht. Besuchen Sie die{' '}
              <RouterLink to="/">Startseite</RouterLink> oder{' '}
              <Link as="button" onClick={onOpen}>
                kontaktieren
              </Link>{' '}
              Sie uns, falls der Fehler weiterhin auftritt.
            </Text>
          </Box>
        </Container>
      </Flex>

      <ContactFormModal isOpen={isOpen} onClose={onClose} />
    </Layout>
  );
};

export default NotFoundPage;
